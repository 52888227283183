/* Global imports */
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { getValue } from "../../helper/translation";

/* Local imports */

/* Component definition */
const Box = ({ className, headline, children, locale }) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  return (
    <div className={`sidebarBox ${className}`}>
      <h5 className="headline">{getValue(headline, localizedMicrocopy)}</h5>
      {children}
    </div>
  );
};
/* PropTypes */
Box.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  locale: PropTypes.oneOf(["en-US"]),
};
Box.defaultProps = {
  className: "",
  headline: "Box headline",
  children: [],
  locale: "en-US",
};

/* Local utility functions */

/* Styles */

export default Box;
