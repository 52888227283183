/* Global imports */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

/* Local imports */
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";
import { renderDynamicComponents } from "../components";
import Box from "../components/sidebar/box";
import Link from "../helper/link";
import { getValue } from "../helper/translation";
import { dtrOptions } from "../helper/richText";

/* Component definition */
const Participation = ({ pageContext }) => {
  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
    linkedEvent,
  } = pageContext;
  const siteSettings = settings;
  const { microcopy } = settings;

  let sidebarComponent;
  let faqComponent;
  let freeTextComponent;
  if (contentComponents) {
    // Take component from ContentComponents
    sidebarComponent = contentComponents.filter(obj => {
      return obj.internal.type === "ContentfulComponentSidebar";
    });
    faqComponent = contentComponents.filter(obj => {
      return obj.internal.type === "ContentfulFaq";
    });
    freeTextComponent = contentComponents.filter(obj => {
      return obj.internal.type === "ContentfulComponentFreeText";
    });
  }

  return (
    <Layout
      siteSettings={siteSettings}
      showBreadcrumbNavigation={showBreadcrumbNavigation}
      showNews={showNews}
    >
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Container fluid className="participationPageContainer pt-5">
        <Row className="sessionContent">
          <Col sm={12} md={12} lg={4} xl={4} className="sidebarContainer">
            {sidebarComponent && (
              <div className="sidebarInsideContainer">
                {sidebarComponent &&
                  sidebarComponent[0]?.components.map(component => {
                    return (
                      <Box
                        headline={`${component.title}`}
                        className="sidebarBox"
                      >
                        <div className="infoBox">
                          <div>
                            {documentToReactComponents(
                              component.body.json,
                              dtrOptions
                            )}
                          </div>
                          <div className="btnContainer">
                            {component.link && (
                              <Link
                                className="button button--inverted button--link"
                                to={component.link.url}
                              >
                                {getValue(
                                  `${component.link.linkText}`,
                                  microcopy
                                )}
                              </Link>
                            )}
                            {component.internalLink && (
                              <Link
                                className="button button--inverted button--link"
                                to={component.internalLink.slug}
                              >
                                {getValue(
                                  `actions.view${component.internalLink.slug}`,
                                  microcopy
                                )}
                              </Link>
                            )}
                          </div>
                        </div>
                      </Box>
                    );
                  })}
              </div>
            )}
          </Col>
          <Col sm={12} md={12} lg={8} xl={8}>
            <article
              className={`teaser-card 
          
                  `}
            >
              <Container className="p-0">
                {/* <Row noGutters={!big}> */}
                <Row>
                  <Col
                    className="d-flex align-items-center "
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="teaser-card-content">
                      <section className="participationFreeText">
                        {freeTextComponent &&
                          renderDynamicComponents(
                            freeTextComponent,
                            node_locale
                          )}
                      </section>
                      <section className="participationFaqSection">
                        <h3 className="font-weight-normal">
                          {getValue("labels.FAQ", microcopy)}
                        </h3>
                        {faqComponent.length &&
                          faqComponent[0].items.map(faq => {
                            return (
                              <>
                                <div className="faqContainer">
                                  <div className="faqQuestion font-weight-bold pb-3">
                                    {documentToReactComponents(
                                      faq.question.json,

                                      dtrOptions
                                    )}
                                  </div>
                                  <div className="faqAnswer">
                                    {documentToReactComponents(
                                      faq.answer.json,

                                      dtrOptions
                                    )}
                                  </div>
                                </div>
                                <hr></hr>
                              </>
                            );
                          })}
                      </section>
                    </div>
                  </Col>
                </Row>
              </Container>
            </article>
          </Col>
        </Row>
      </Container>
      <News node_locale={node_locale} settings={settings} visible={showNews} />
    </Layout>
  );
};
/* PropTypes */
Participation.propTypes = {};
Participation.defaultProps = {};

/* Local utility functions */

/* Styles */

export default Participation;
