/* Global imports */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, useStaticQuery } from "gatsby";
import * as _ from "lodash";
import moment from "moment";
import "moment/locale/de";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselControl from "../components/carouselControl";
import TeaserCard from "../components/teaserCard";
import { carouselBreakpoints } from "../helper/carousel";
import Link, { getArticleLink, getLocalizedLink } from "../helper/link";
import { getLocalizedTags, getValue } from "../helper/translation";
/* Component definition */
const News = ({ settings, node_locale, visible }) => {
  const { microcopy } = settings;
  const {
    allNewsArticles: { edges: allNewsArticles },
  } = useStaticQuery(graphql`
    {
      allNewsArticles: allContentfulArticleGeneral(
        limit: 4,
        sort: { order: DESC, fields: articleDetail___postDateTime }
      ) {
        ...articleDetailFields
      }
    }
  `);
  // const {
  //   allNewsArticles: { edges: allNewsArticles },
  // } = useStaticQuery(graphql`
  //   {
  //     allNewsArticles: allContentfulArticleGeneral(
  //       filter: {
  //         articleDetail: {
  //           typeOfArticle: { contentful_id: { eq: "blog" } }
  //         }
  //       }
  //       limit: 4,
  //       sort: { order: DESC, fields: articleDetail___postDateTime }
  //     ) {
  //       ...articleDetailFields
  //     }
  //   }
  // `);

  moment.locale(node_locale.split("-")[0]);

  const allArticleDetail = allNewsArticles
    .filter(edge => edge.node.articleDetail.node_locale === node_locale)
    .map(edge => ({
      ...edge.node.articleDetail,
      generalTitle: edge.node.title,
    }));

  const responsive = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      items: 4,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 4,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 3,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 2,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
      partialVisible: true,
    },
  };

  return visible ? (
    <section className="news">
      <Container fluid>
        <Row>
          <Col className="news__title d-flex align-items-center justify-content-between">
            <h4 className="font-weight-normal">
              {getValue("labels.currentTopics", microcopy)}
            </h4>
            <Link
              className={"news-read-more"}
              to={getLocalizedLink(`/blog`, node_locale)}
            >
              {getValue("actions.showMore", microcopy)}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="lg"
                className="news-read-more-icon"
              />
            </Link>
          </Col>
        </Row>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          className={"bg-white"}
          containerClass="frient-carousel align-items-start"
          customButtonGroup={<CarouselControl />}
          arrows={false}
          dots={false}
          itemClass="frient-carousel-item"
          dotListClass="frient-carousel-dots"
        >
          {allArticleDetail.map(articleDetail => (
            <Col xs={12} className={"h-100"}>
              <TeaserCard
                title={articleDetail.articleTitle}
                tags={getLocalizedTags(
                  articleDetail.visibleTags,
                  [
                    ...articleDetail.topic,
                    articleDetail.focusOfWork,
                    articleDetail.region,
                  ],
                  articleDetail.node_locale
                )}
                createdAt={moment(new Date(articleDetail.postDateTime)).format(
                  "DD MMMM YYYY"
                )}
                articleType={_.get(articleDetail, ["typeOfArticle", "name"])}
                readMoreLabelText={getValue("actions.readMore", microcopy)}
                link={getArticleLink(
                  articleDetail.generalTitle,
                  articleDetail.node_locale
                )}
                image={
                  articleDetail.imageLandscape
                    ? articleDetail.imageLandscape.imageLandscape.fluid
                    : articleDetail.imageSquare
                    ? articleDetail.imageSquare.imageSquare.fluid
                    : ""
                }
              />
            </Col>
          ))}
        </Carousel>
      </Container>
    </section>
  ) : (
    ""
  );
};
/* PropTypes */
News.propTypes = {
  news: PropTypes.array,
  settings: PropTypes.object,
  visible: PropTypes.bool,
};
News.defaultProps = {
  items: [],
  settings: {},
  visible: false,
};

/* Local utility functions */

/* Styles */
export default News;
